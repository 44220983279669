import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { url: String, closeTabTimeout: Number };

  connect() {
    setTimeout(() => {
      window.close();
    }, this.closeTabTimeoutValue * 1000);
  }
}
