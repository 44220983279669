import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    
    forcereload: { type: Boolean, default: false },
  };
  connect() {
    this.element.addEventListener("submit", (ev) => {
      const data = new FormData(ev.target);

      const url = new URL(window.location);

      for (const [key, value] of data.entries()) {
        url.searchParams.set(key, value);
      }
      
      if (this.forcereloadValue){
        location.replace(url);
      }
      else{
        window.history.pushState({}, "", url);
      }
    });
  }
}
