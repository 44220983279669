import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "panel",
    "filterButton",
    "overlay",
    "form",
    "count",
    "fields",
    "activeFilters",
  ];

  calculateActiveFilters() {
    const fields = this.fieldsTarget.querySelectorAll(
      "input, select, textarea"
    );
    let activeCount = 0;

    fields.forEach((field) => {
      if (
        ((field.type === "checkbox" || field.type === "radio") &&
          field.checked) ||
        (field.type !== "checkbox" &&
          field.type !== "radio" &&
          field.value.trim() !== "")
      ) {
        activeCount++;
      }
    });
    return activeCount;
  }

  connect() {
    this.isVisible = false;
    const count = this.calculateActiveFilters();
    if (count) {
      this.countTarget.textContent = count;
      this.countTarget.classList.remove("hidden");
    }
  }

  toggle() {
    console.log("toggle");
    this.isVisible = !this.isVisible;

    if (this.isVisible) {
      this.showPanel();
    } else {
      this.hidePanel();
    }
  }

  showPanel() {
    this.overlayTarget.classList.remove("opacity-0", "pointer-events-none");
    this.overlayTarget.classList.add("opacity-100");

    this.panelTarget.classList.remove("opacity-0", "pointer-events-none");
    this.panelTarget.classList.add("opacity-100");
  }

  hidePanel() {
    this.overlayTarget.classList.remove("opacity-100");
    this.overlayTarget.classList.add("opacity-0", "pointer-events-none");

    this.panelTarget.classList.remove("opacity-100");
    this.panelTarget.classList.add("opacity-0", "pointer-events-none");
  }
}
