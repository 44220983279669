import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { url: String, delay: { type: Number, default: 0 } };

  connect() {
    if (this.delayValue > 0) {
      setTimeout(() => {
        window.location = this.urlValue;
      }, this.delayValue);
    } else {
      window.location = this.urlValue;
    }
  }
}
