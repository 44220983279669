import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { start: false };

  connect() {
    if (this.startValue) {
      this.toggle();
    }
  }

  toggle() {
    window.dispatchEvent(new CustomEvent("coverNavbar"));
  }
}
