import { Controller } from "@hotwired/stimulus";
export default class extends Controller {
  static targets = [
    "step",
    "nextButton",
    "prevButton",
    "submitButton",
    "toggleFields",
    "previewDetail",
  ];
  static values = {
    currentStep: Number,
    stepToDetailMap: Object,
  };

  connect() {
    if (!this.hasStepToDetailMapValue) {
      this.stepToDetailMapValue = this.generateStepToDetailMap();
    }
    this.showCurrentStep();
  }

  generateStepToDetailMap() {
    const map = {};
    this.stepTargets.forEach((step, index) => {
      const detailIndex = step.dataset.previewDetailIndex;
      map[index] = detailIndex ? parseInt(detailIndex) : index;
    });
    return map;
  }

  next() {
    if (this.currentStepValue < this.stepTargets.length - 1) {
      this.currentStepValue++;
      this.showCurrentStep();
    }
  }

  prev() {
    if (this.currentStepValue > 0) {
      this.currentStepValue--;
      this.showCurrentStep();
    }
  }

  showCurrentStep() {
    this.stepTargets.forEach((step, index) => {
      step.classList.toggle("hidden", index !== this.currentStepValue);
    });

    this.prevButtonTarget.classList.toggle(
      "hidden",
      this.currentStepValue === 0
    );
    this.nextButtonTarget.classList.toggle(
      "hidden",
      this.currentStepValue === this.stepTargets.length - 1
    );
    this.submitButtonTarget.classList.toggle(
      "hidden",
      this.currentStepValue !== this.stepTargets.length - 1
    );

    const currentDetailIndex = this.stepToDetailMapValue[this.currentStepValue];
    this.previewDetailTargets.forEach((detail, index) => {
      if (index === currentDetailIndex) {
        detail.setAttribute("open", "");
      } else {
        detail.removeAttribute("open");
      }
    });
  }

  updateButtons() {
    this.prevButtonTarget.disabled = this.currentStep === 0;
    this.nextButtonTarget.disabled =
      this.currentStep === this.stepTargets.length - 1;
  }

  validateCurrentStep() {
    const currentStepElement = this.stepTargets[this.currentStep];
    const requiredInputs = currentStepElement.querySelectorAll(
      "[data-required='true']"
    );
    let isValid = true;

    requiredInputs.forEach((input) => {
      if (!input.value.trim()) {
        input.classList.add("input-error");
        isValid = false;
      } else {
        input.classList.remove("input-error");
      }
    });

    return isValid;
  }

  updatePreview(event) {
    const field = event.target;

    const previewField = document.getElementById(`preview_${field.id}`);
    const showField = document.getElementById(`show_${field.id}`);
    // if type checkbox change to SI or NO
    if (field.type === "checkbox") {
      previewField.textContent = field.checked ? "SI" : "NO";
    } else {
      if (previewField) {
        if (field.tagName === "SELECT") {
          const selectedOption = field.options[field.selectedIndex];
          previewField.textContent = selectedOption.text;
        } else {
          previewField.textContent = field.value || "(Pendiente)";
        }
      }
      if (showField) {
        showField.textContent = field.value;
      }
      if (field.name === "ges_report_form[prevision]") {
        document.getElementById(
          "preview_ges_report_form_prevision"
        ).textContent = field.value;
      } else if (field.name === "ges_report_form[oncology_status]") {
        const label = document.querySelector(
          `label[for="${field.id}"]`
        ).textContent;

        document.getElementById(
          "preview_ges_report_form_oncology_status"
        ).textContent = label;
      } else if (field.name === "ges_report_form[ges_code_id]") {
        const label = document.querySelector(
          `label[for="${field.id}"]`
        ).textContent;

        document.getElementById(
          "preview_ges_report_form_ges_code_id"
        ).textContent = label;
      }
    }
  }

  togglePreviewDetail(event) {
    const checkbox = event.target;
    const detailIndex = parseInt(checkbox.dataset.previewDetailIndex);
    const detailElement = this.previewDetailTargets[detailIndex];

    if (detailElement) {
      detailElement.setAttribute("open", "");
    }
  }

  hidePreviewDetail(event) {
    const checkbox = event.target;
    const detailIndex = parseInt(checkbox.dataset.previewDetailIndex);
    const detailElement = this.previewDetailTargets[detailIndex];

    if (detailElement) {
      detailElement.removeAttribute("open");
    }
  }

  toggleFields(event) {
    const field = event.target;

    if (field.checked) {
      this.toggleFieldsTarget.classList.remove("hidden");
    } else {
      this.toggleFieldsTarget.classList.add("hidden");
    }
  }

  hideToggleFields(event) {
    this.toggleFieldsTarget.classList.add("hidden");
  }
}
