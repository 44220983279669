import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["toggleable", "background"]; // Define los targets
  static values = { start: false };

  connect() {
    if (this.startValue) {
      this.toggle();
    }
  }

  toggle() {
    this.toggleableTarget.classList.toggle("hidden");

    // Toggle para el fondo, si existe
    if (this.hasBackgroundTarget) {
      if (this.backgroundTarget.classList.contains("hidden")) {
        this.backgroundTarget.classList.remove("hidden", "animate-fadeOut");
        this.backgroundTarget.classList.add("animate-fadeIn");
      } else {
        this.backgroundTarget.classList.remove("animate-fadeIn");
        this.backgroundTarget.classList.add("animate-fadeOut", "hidden");
      }
    }
  }
}
