import { Controller } from "@hotwired/stimulus";
import Lottie from "lottie-web";

export default class extends Controller {
  static values = {
    src: String,
    autoplay: Boolean,
    loop: Boolean,
    speed: Number,
  };

  connect() {
    let options = {
      path: this.srcValue,
      container: this.element.querySelector("#canvas"),
      loop: this.loopValue,
      autoplay: this.autoplayValue,
      renderer: "canvas",
    };

    console.log(options);

    this.player = Lottie.loadAnimation(options);

    this.player.setSpeed(this.speedValue ?? 1);
  }
}
