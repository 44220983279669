import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  values = { alreadyHidden: true };

  connect() {
    this.observeClassChanges();
  }

  observeClassChanges() {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.attributeName === "class") {
          this.handleClassChange();
        }
      });
    });

    observer.observe(this.element, { attributes: true });
  }

  handleClassChange() {
    if (
      this.element.classList.contains("hidden") &&
      !this.values.alreadyHidden
    ) {
      this.values.alreadyHidden = true;
      this.element.classList.remove("animate-fadeIn");
      this.element.classList.add("animate-fadeOut");
    } else if (
      !this.element.classList.contains("hidden") &&
      this.values.alreadyHidden
    ) {
      this.values.alreadyHidden = false;
      this.element.classList.remove("animate-fadeOut");
      this.element.classList.add("animate-fadeIn");
    }
  }
}
