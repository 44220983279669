// app/javascript/controllers/ds_button_controller.js
import { Controller } from "@hotwired/stimulus";
export default class extends Controller {
  static targets = ["button"]

  initialize() {
    this.loading = false
    this.originalContent = this.buttonTarget.innerHTML
  }

  toggleLoading() {
    this.loading = !this.loading
    const button = this.buttonTarget

    if (this.loading) {
      button.setAttribute("data-loading", "true")   
      button.innerHTML = `<span class="loaders"></span>`
      button.classList.add("cursor-wait")
    } 
   else {
      button.removeAttribute("data-loading")
       button.innerHTML = this.originalContent
       button.classList.remove("cursor-wait")
     }
  }
}

