import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "carousel",
    "stickyFrame",
    "box",
    "shadow",
    "filters",
    "dates",
    "selectedProfession",
    "selectedService",
  ];

  connect() {
    window.addEventListener("scroll", this.handleScroll.bind(this));
  }

  disconnect() {
    window.removeEventListener("scroll", this.handleScroll.bind(this));
  }

  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: Add smooth scrolling behavior
    });
  }

  handleScroll() {
    const scrollThreshold = 40;

    const animate = window.scrollY > scrollThreshold;

    this.animateCarousel(animate);
    this.animateStickyFrame(animate);
    this.animateBox(animate);
    this.animateShadow(animate);
    this.animateFilters(animate);
    this.animateSelectedProfession(animate);
    this.animateDates(animate);
    this.animateSelectedService(animate);
  }

  animateCarousel(animate) {
    if (animate) {
      this.carouselTarget.classList.add("-translate-y-16");
    } else {
      this.carouselTarget.classList.remove("-translate-y-16");
    }
  }

  animateStickyFrame(animate) {
    if (animate) {
      this.stickyFrameTarget.classList.add(
        "md:scale-y-90",
        "-translate-y-24",
        "md:!pt-0",
        "z-10"
      );
    } else {
      this.stickyFrameTarget.classList.remove(
        "md:scale-y-90",
        "-translate-y-24",
        "md:!pt-0",
        "z-10"
      );
    }
  }

  animateBox(animate) {
    if (animate) {
      this.boxTarget.classList.add("md:scale-x-90");
      this.boxTarget.classList.remove("md:shadow-lg");
    } else {
      this.boxTarget.classList.remove("md:scale-x-90");
      this.boxTarget.classList.add("md:shadow-lg");
    }
  }

  animateShadow(animate) {
    if (animate) {
      this.shadowTarget.classList.remove("hidden");
    } else {
      this.shadowTarget.classList.add("hidden");
    }
  }

  animateFilters(animate) {
    if (animate) {
      this.filtersTarget.classList.remove("border-x");
      this.filtersTarget.classList.add("translate-y-20", "-z-10");
    } else {
      this.filtersTarget.classList.add("border-x");
      this.filtersTarget.classList.remove("translate-y-20", "-z-10");
    }
  }

  animateSelectedProfession(animate) {
    if (animate) {
      this.selectedProfessionTarget.classList.add(
        "translate-y-36",
        "scale-y-110"
      );
    } else {
      this.selectedProfessionTarget.classList.remove(
        "translate-y-36",
        "scale-y-110"
      );
    }
  }

  animateSelectedService(animate) {
    if (animate) {
      this.selectedServiceTarget.classList.add("translate-y-36", "scale-y-110");
    } else {
      this.selectedServiceTarget.classList.remove(
        "translate-y-36",
        "scale-y-110"
      );
    }
  }

  animateDates(animate) {
    if (animate) {
      this.datesTarget.classList.add(
        "md:!border-none",
        "rounded-t-2xl",
        "md:!p-2"
      );
    } else {
      this.datesTarget.classList.remove(
        "md:!border-none",
        "rounded-t-2xl",
        "md:!p-2"
      );
    }
  }
}
