import { Controller } from "@hotwired/stimulus";
import { enter, leave } from "el-transition";

export default class extends Controller {
  connect() {
    enter(this.element);

    setTimeout(() => {
      this.leaveAndRemove(this.element);
    }, 10000);
  }

  leaveAndRemove(element) {
    leave(element);
    setTimeout(() => {
      element.remove();
    }, 1000);
  }

  hide() {
    this.leaveAndRemove(this.element);
  }
}
