import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    //wait 1 second and add expansible to this element class
    setTimeout(() => {
      this.element.classList.add("animate-expand");
    }, 1000);
  }
}
