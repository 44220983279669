import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { initialHeight: String }; // Define los valores
  static targets = ["section", "button"]; // Define los targets

  toggle() {
    // expand section from defined h to its full height
    if (this.hasSectionTarget) {
      if (!this.sectionTarget.classList.contains("animate-expandHeight")) {
        this.sectionTarget.classList.add("animate-expandHeight");
        this.sectionTarget.classList.remove("animate-collapseHeight");
      } else {
        this.sectionTarget.classList.remove("animate-expandHeight");
        this.sectionTarget.classList.add("animate-collapseHeight");
      }
    }

    // flip button vertically
    if (this.hasButtonTarget) {
      if (this.buttonTarget.classList.contains("animate-flipX")) {
        this.buttonTarget.classList.remove("animate-flipX");
        this.buttonTarget.classList.add("animate-flipXReverse");
      } else {
        this.buttonTarget.classList.add("animate-flipX");
        this.buttonTarget.classList.remove("animate-flipXReverse");
      }
    }
  }
}
